/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */


/* =========================
   STICKY SIDEBAR
   ========================= */

   .sticky-sidebar-lesson {
    width: 80%;
    margin: 0 auto;
  }
  
  .sticky-sidebar-lesson::after {
    display: table;
    content: "";
    clear: both;
   
  }
  
  .sticky-sidebar-lesson .sandbox {
    width: 60%;
    margin: 0 0 40px;
    float: left;
  
  }
  
  .sticky-sidebar {
    background: #e7eef1;
    padding: 40px 40px 60px;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 0 12px 0 -6px #fa90a3;
  
    position: fixed;
    right: 5%;
    width: 36%;
    float: right;
    border-radius: 200px;
  }
  