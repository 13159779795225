.ingredient-form {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;



  background-image: -webkit-linear-gradient(135deg, #0eeff0 30%, #088f90 90%);
  background-image: linear-gradient(135deg, #0eeff0 30%, #088f90 90%);
  position: relative;
  z-index: 3;
  padding: 100px;
  font-size:16px;
  color: #999999;
}

.form-control label,
.form-control input {
  display: block;
  width: 100%;






  display: inline-block;
  width: 100%;
  padding: 0 20px;
  line-height: 50px;
  height: 50px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-bottom-width: 3px;
  border-radius: 5px;
  outline: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.form-control input {
  font: inherit;
  padding: 0.1rem 0.25rem;
  border: none;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1rem;
}

.form-control input:focus {
  outline: none;
  border-bottom-color: #ff2058;
}

.ingredient-form__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  display: block;
  width: 100%;
  max-width: 300px;
  margin: auto;
  padding: 30px;
  background-color: white;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
button{
  padding: 15px 25px ;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  justify-content: center;




  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0 20px;
  font-size: 1rem;
  background-color: #f2395a;
  background-size: 100%;
  background-image: -webkit-linear-gradient(45deg,
                                             rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%,
                                             rgba(0, 0, 0, 0) 50%,
                                             rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%,
                                             rgba(0, 0, 0, 0));
  background-image:          linear-gradient(45deg,
                                             rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%,
                                             rgba(0, 0, 0, 0) 50%,
                                             rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%,
                                             rgba(0, 0, 0, 0));
  background-size: 20px 20px, 100% 100%;
  border: 1px solid #f02146;
  border-bottom-width: 3px;
  color: white;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

}
.button:hover {background-color: #3e8e41



}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);




  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  -webkit-transform: scale(0.9) translate(0, 0);
          transform: scale(0.9) translate(0, 0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}