
.om {
  position: relative;
  background: orangered;
  border: none;
  color: white;
  padding: 15px 24px;
  font-size: 1.4rem;
  box-shadow: -6px 6px 0 hsl(16, 100%, 30%);
  outline: none;
border-radius: 24px;
font-family: 'Pacifico', cursive;
}

  .om:hover {
    background: hsl(16, 100%, 45%);
  }

  .om:active {
    background: hsl(16, 100%, 40%);
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 hsl(16, 100%, 30%);
  }

  .om::before {
    position: absolute;
    display: block;
    content: "";
    height: 0;
    width: 0;
    /* border width needs to be size of the element's box shadow */
    border: solid 6px transparent;

    /* border width needs to be size of the element's box shadow */
    border-right: solid 6px hsl(16, 100%, 30%);

    /* no width for left, makes measurements easier */
    border-left-width: 0px;

    background: none;
    top: 0px;

    /* needs to be the same size as border width */
    left: -6px;
  }

  .om::after {
    position: absolute;
    display: block;
    content: "";
    height: 0;
    width: 0;
    border: solid 6px transparent;
    border-top: solid 6px hsl(16, 100%, 30%);
    border-bottom-width: 0px;
    background: none;
    right: 0;
    bottom: -6px;
  }

    .om:active::before {
      border: solid 3px transparent;
      border-right: solid 3px hsl(16, 100%, 30%);
      border-left-width: 0px;
      left: -3px;
    }

    .om:active::after {
      border: solid 3px transparent;
      border-top: solid 3px hsl(16, 100%, 30%);
      border-bottom-width: 0px;
      bottom: -3px;
    }
    .om2 {
      font-size: 2.5em;
      text-align: center;
      text-transform: uppercase;
      color: #3ff239;
      -webkit-transform: skew(-5deg, -5deg) rotate(5deg);
              transform: skew(-5deg, -5deg) rotate(5deg);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      text-shadow: 1px 1px #d10e31,
                   2px 2px #d10e31,
                   3px 3px #d10e31,
                   4px 4px #d10e31,
                   5px 5px #d10e31,
                   6px 6px #d10e31,
                   7px 7px #d10e31,
                   8px 8px #890920,
                   9px 9px #890920,
                   10px 10px #890920,
                   11px 11px 15px rgba(0,0,0,0.5);
    }
    .img2 {
      -webkit-clip-path: polygon(0% 0%,
                                 100% 0%,
                                 100% 75%,
                                 75% 75%,
                                 75% 100%,
                                 50% 75%,
                                 0% 75%);
              clip-path: polygon(0% 0%,
                                 100% 0%,
                                 100% 75%,
                                 75% 75%,
                                 75% 100%,
                                 50% 75%,
                                 0% 75%);
    }
    .om{
      /* top: 100%;
      left: 50%; */
      transform: translateX(-50%);
      border-top-width: 0;
      border-bottom-color: rgba(0, 0, 0, 0.8);


      position: relative;
      /* height: 60px;
      width: 250px; */
      background: #f2395a;
      color: white;
      text-align: center;
      /* line-height: 60px;
      font-size: 18px; */
      margin: 0 auto;
    }