.ingredient-list {
  width: 30rem;
  max-width: 80%;
  margin: auto;
}

.ingredient-list h2 {
  border-bottom: 3px solid #ccc;
  padding-bottom: 1rem;
}

.ingredient-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ingredient-list li {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: space-between;
}
