/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */

   .final__triangle {
    position: relative;
    height: 320px;
    clip-path: polygon(50% 0%, 20% 100%, 80% 100%);
  }
  
  .final__triangle div {
    position: absolute;
    top: 0;
    line-height: 100px;
    font-size: 0.8em;
    text-align: center;
    background: red;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    transition: all ease 0.6s;
    color: red;
  }
  
  .final__triangle .top {
    z-index: 1;
  }
  
    .final__triangle:hover div {
      color: white;
    }
  
  .final__triangle:hover .mid {
    top: 110px;
    background: green;
  }
  
  .final__triangle:hover .bottom {
    top: 220px;
    background: blue;
  }
  