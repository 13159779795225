@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  font-family: "Mulish", sans-serif;
}
.navbar
{
  background-color: #fff;
}

.navbar-brand
{
  font-size: 1.9rem !important;
  color: #565387 !important;
}
.navbar a{
  font-size: 1.3rem !important;
  text-transform: capitalize;
}
.menu_active
{
  font-weight: bold;
  border-bottom: 1px solid #565387;
}
.navbar a:hover{
 color: #3498db;
}
@media(max-width:991px)
{
  .navbar-brand{
    font-size: 1.5rem !important;
  }
}
body {
  margin: 0;
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#header{
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}
#header h1{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}
#header .brand-name{
 color: #3498db;
}
#header h2{
 color: #484848;
 font-size: 25px;
}
#header .btn-get-started{

  font-size: 17px;
  font-weight: 500;
  color: #3498db;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  border: 2px solid #3498db;
  text-decoration: none;
}
#header .btn-get-started:hover{

 background: #3498db;
 color: #fff;
}
.header-img{
  text-align: right;
}
#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@media (max-width:991px){
  #header{
    height: 80vh;
    text-align: center;
  }
  #header .header-img{
 
    text-align: center;
  }

  #header .header-img img{
 
    width:60%;
  }
}


@media (max-width:768px){
  #header{
    margin-top: 20px;
  }
  #header h1{
 
   font-size: 28px;
  }

  #header h2{
 
    font-size: 18px;
    margin-bottom: 30px;
   }


   #header .header-img img{
 
   width: 70%;
   }
}

@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  font-family: "Mulish", sans-serif;
}
.navbar
{
  background-color: #fff;
}

.navbar-brand
{
  font-size: 1.9rem !important;
  color: #565387 !important;
}
.navbar a{
  font-size: 1.3rem !important;
  text-transform: capitalize;
}
.menu_active
{
  font-weight: bold;
  border-bottom: 1px solid #565387;
}
.navbar a:hover{
 color: #3498db;
}
@media(max-width:991px)
{
  .navbar-brand{
    font-size: 1.5rem !important;
  }
}
body {
  margin: 0;
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#header{
  width: 100%;
  height: 80vh;
  margin-top: 70px;
  font-family: 'Chela One', cursive;
}
#header h1{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
  font-family: 'Pacifico', cursive;
}
#header .brand-name{
 color: #3498db;
}
#header h2{
 color: #484848;
 font-size: 25px;
 font-family: 'Sansita Swashed', cursive;
}
#header .btn-get-started{

  font-size: 17px;
  font-weight: 500;
  color: #3498db;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  border: 2px solid #3498db;
  text-decoration: none;
  font-family: 'Sansita Swashed', cursive;
}
#header .btn-get-started:hover{

 background: #3498db;
 color: #fff;
 font-family: 'Sansita Swashed', cursive;
}
.header-img{
  text-align: right;
border-radius: 10px;
  -webkit-clip-path: polygon(0% 0%,
  100% 0%,
  100% 75%,
  75% 75%,
  75% 100%,
  50% 75%,
  0% 75%);
clip-path: polygon(0% 0%,
  100% 0%,
  100% 75%,
  75% 75%,
  75% 100%,
  50% 75%,
  0% 75%);

}
#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@media (max-width:991px){
  #header{
    height: 80vh;
    text-align: center;
    font-family: 'Pacifico', cursive;
  }
  #header .header-img{
 
    text-align: center;
  }

  #header .header-img img{
 
    width:60%;
  }
}


@media (max-width:768px){
  #header{
    margin-top: 20px;
  }
  #header h1{
 
   font-size: 28px;
  }

  #header h2{
 
    font-size: 18px;
    margin-bottom: 30px;
    font-family: 'Pacifico', cursive;
   }


   #header .header-img img{
 
   width: 70%;
   }
}