@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  font-family: "Mulish", sans-serif;
}
.navbar
{
  background-color: #fff;
}

.navbar-brand
{
  font-size: 1.9rem !important;
  color: #565387 !important;
}
.navbar a{
  font-size: 1.3rem !important;
  text-transform: capitalize;
}
.menu_active
{
  font-weight: bold;
  border-bottom: 1px solid #565387;
}
.navbar a:hover{
 color: #3498db;
}
@media(max-width:991px)
{
  .navbar-brand{
    font-size: 1.5rem !important;
  }
}
body {
  margin: 0;
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#header{
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}
#header h1{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}
#header .brand-name{
 color: #3498db;
}
#header h2{
 color: #484848;
 font-size: 25px;
}
#header .btn-get-started{

  font-size: 17px;
  font-weight: 500;
  color: #3498db;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  border: 2px solid #3498db;
  text-decoration: none;
}
#header .btn-get-started:hover{

 background: #3498db;
 color: #fff;
}
.header-img{
  text-align: right;
}
#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@media (max-width:991px){
  #header{
    height: 80vh;
    text-align: center;
  }
  #header .header-img{
 
    text-align: center;
  }

  #header .header-img img{
 
    width:60%;
  }
}


@media (max-width:768px){
  #header{
    margin-top: 20px;
  }
  #header h1{
 
   font-size: 28px;
  }

  #header h2{
 
    font-size: 18px;
    margin-bottom: 30px;
   }


   #header .header-img img{
 
   width: 70%;
   }
}
* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
  font-family: "Mulish", sans-serif;
}
.navbar
{
  background-color: #fff;
}

.navbar-brand
{
  font-size: 1.9rem !important;
  color: #565387 !important;
}
.navbar a{
  font-size: 1.3rem !important;
  text-transform: capitalize;
}
.menu_active
{
  font-weight: bold;
  border-bottom: 1px solid #565387;
}
.navbar a:hover{
 color: #3498db;
}
@media(max-width:991px)
{
  .navbar-brand{
    font-size: 1.5rem !important;
  }
}
body {
  margin: 0;
 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#header{
  width: 100%;
  height: 80vh;
  margin-top: 70px;
  font-family: 'Chela One', cursive;
}
#header h1{
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
  font-family: 'Pacifico', cursive;
}
#header .brand-name{
 color: #3498db;
}
#header h2{
 color: #484848;
 font-size: 25px;
 font-family: 'Sansita Swashed', cursive;
}
#header .btn-get-started{

  font-size: 17px;
  font-weight: 500;
  color: #3498db;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  border: 2px solid #3498db;
  text-decoration: none;
  font-family: 'Sansita Swashed', cursive;
}
#header .btn-get-started:hover{

 background: #3498db;
 color: #fff;
 font-family: 'Sansita Swashed', cursive;
}
.header-img{
  text-align: right;
border-radius: 10px;
  -webkit-clip-path: polygon(0% 0%,
  100% 0%,
  100% 75%,
  75% 75%,
  75% 100%,
  50% 75%,
  0% 75%);
clip-path: polygon(0% 0%,
  100% 0%,
  100% 75%,
  75% 75%,
  75% 100%,
  50% 75%,
  0% 75%);

}
#header .animated{
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@-webkit-keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@keyframes up-down{
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(-20px);
  }
}
@media (max-width:991px){
  #header{
    height: 80vh;
    text-align: center;
    font-family: 'Pacifico', cursive;
  }
  #header .header-img{
 
    text-align: center;
  }

  #header .header-img img{
 
    width:60%;
  }
}


@media (max-width:768px){
  #header{
    margin-top: 20px;
  }
  #header h1{
 
   font-size: 28px;
  }

  #header h2{
 
    font-size: 18px;
    margin-bottom: 30px;
    font-family: 'Pacifico', cursive;
   }


   #header .header-img img{
 
   width: 70%;
   }
}
/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */


/* =========================
   PACMAN!
   ========================= */

   .final__path {
    position: relative;
    width: 100%;
    height: 100px;
    background: #333;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .final__path::after {
      content: "";
      position: absolute;
      display: block;
      border-top: 12px dotted white;
      color: white;
      width: 90%;
      top: 50%;
      left: 5%;
      margin-top: -6px;
    }

  .final__pacman {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: -20%;
    -webkit-animation: final__moveForward 5s linear infinite;
            animation: final__moveForward 5s linear infinite;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }

    .final__pacman::before, .final__pacman::after {
      content: "";
      display: block;
      height: 0;
      width: 0;
      position: absolute;
      border: solid 30px yellow;
      margin-top: -30px;
      border-radius: 50%;
      border-right-color: transparent;
      border-bottom-color: transparent;
      -webkit-animation: final__mouthTop 0.7s ease infinite;
              animation: final__mouthTop 0.7s ease infinite;
    }

    .final__pacman::after {
      border-color: yellow;
      border-right-color: transparent;
      border-top-color: transparent;
      -webkit-animation: final__mouthBottom 0.7s ease infinite;
              animation: final__mouthBottom 0.7s ease infinite;
    }

  .final__ghost {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 20%;
    margin-top: -35px;
    background: red;
    height: 70px;
    width: 60px;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    -webkit-animation: final__moveForward 5s linear infinite, final__colourSwap 0.5s linear infinite;
            animation: final__moveForward 5s linear infinite, final__colourSwap 0.5s linear infinite;
  }

    /* using these for eyes */
    .final__ghost::before, .final__ghost::after {
      content: "";
      position: absolute;
      background: white;
      height: 25px;
      width: 20px;
      border-radius: 100%;
      top: 15px;
      left: 4px;
    }

    .final__ghost::after {
      left: 28px;
    }

  .final__eyes::before, .final__eyes::after {
    content: "";
    position: absolute;
    background: blue;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    top: 25px;
    left: 5px;
    z-index: 99;
    -webkit-animation: final__peeperLeft 0.7s linear infinite;
            animation: final__peeperLeft 0.7s linear infinite;
  }

    .final__eyes::after {
      left: 30px;
      -webkit-animation: final__peeperRight 0.7s linear infinite;
              animation: final__peeperRight 0.7s linear infinite;
    }

  .final__skirt {
    position: absolute;
    height: 0;
    width: 0;
    border: solid 10px #333;
    border-top-color: transparent;
    bottom: -10px;
  }
    .final__skirt::after, .final__skirt::before {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      border: solid 10px #333;
      border-top-color: transparent;
      bottom: -10px;
      left: 10px;
    }
    .final__skirt::after {
      border-color: #333;
      border-top-color: transparent;
      bottom: -10px;
      left: 30px;
    }

  @-webkit-keyframes final__moveForward {
    0% {
      left: -20%;
    }
    100% {
      left: 100%;
    }
  }

  @keyframes final__moveForward {
    0% {
      left: -20%;
    }
    100% {
      left: 100%;
    }
  }

  @-webkit-keyframes final__mouthTop {
    50% {
      transform: rotate(44deg);
    }
  }

  @keyframes final__mouthTop {
    50% {
      transform: rotate(44deg);
    }
  }

  @-webkit-keyframes final__mouthBottom {
    50% {
      transform: rotate(-44deg);
    }
  }

  @keyframes final__mouthBottom {
    50% {
      transform: rotate(-44deg);
    }
  }

  @-webkit-keyframes final__peeperLeft {
    0%, 100% {
      left: 5px;
    }
    50% {
      left: 10px;
    }
  }

  @keyframes final__peeperLeft {
    0%, 100% {
      left: 5px;
    }
    50% {
      left: 10px;
    }
  }

  @-webkit-keyframes final__peeperRight {
    0%, 100% {
      left: 30px;
    }
    50% {
      left: 38px;
    }
  }

  @keyframes final__peeperRight {
    0%, 100% {
      left: 30px;
    }
    50% {
      left: 38px;
    }
  }

  @-webkit-keyframes final__colourSwap {
    50% {
      background: hotpink;
    }
  }

  @keyframes final__colourSwap {
    50% {
      background: hotpink;
    }
  }


.om {
  position: relative;
  background: orangered;
  border: none;
  color: white;
  padding: 15px 24px;
  font-size: 1.4rem;
  box-shadow: -6px 6px 0 hsl(16, 100%, 30%);
  outline: none;
border-radius: 24px;
font-family: 'Pacifico', cursive;
}

  .om:hover {
    background: hsl(16, 100%, 45%);
  }

  .om:active {
    background: hsl(16, 100%, 40%);
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 hsl(16, 100%, 30%);
  }

  .om::before {
    position: absolute;
    display: block;
    content: "";
    height: 0;
    width: 0;
    /* border width needs to be size of the element's box shadow */
    border: solid 6px transparent;

    /* border width needs to be size of the element's box shadow */
    border-right: solid 6px hsl(16, 100%, 30%);

    /* no width for left, makes measurements easier */
    border-left-width: 0px;

    background: none;
    top: 0px;

    /* needs to be the same size as border width */
    left: -6px;
  }

  .om::after {
    position: absolute;
    display: block;
    content: "";
    height: 0;
    width: 0;
    border: solid 6px transparent;
    border-top: solid 6px hsl(16, 100%, 30%);
    border-bottom-width: 0px;
    background: none;
    right: 0;
    bottom: -6px;
  }

    .om:active::before {
      border: solid 3px transparent;
      border-right: solid 3px hsl(16, 100%, 30%);
      border-left-width: 0px;
      left: -3px;
    }

    .om:active::after {
      border: solid 3px transparent;
      border-top: solid 3px hsl(16, 100%, 30%);
      border-bottom-width: 0px;
      bottom: -3px;
    }
    .om2 {
      font-size: 2.5em;
      text-align: center;
      text-transform: uppercase;
      color: #3ff239;
      transform: skew(-5deg, -5deg) rotate(5deg);
      transform-origin: center center;
      text-shadow: 1px 1px #d10e31,
                   2px 2px #d10e31,
                   3px 3px #d10e31,
                   4px 4px #d10e31,
                   5px 5px #d10e31,
                   6px 6px #d10e31,
                   7px 7px #d10e31,
                   8px 8px #890920,
                   9px 9px #890920,
                   10px 10px #890920,
                   11px 11px 15px rgba(0,0,0,0.5);
    }
    .img2 {
      -webkit-clip-path: polygon(0% 0%,
                                 100% 0%,
                                 100% 75%,
                                 75% 75%,
                                 75% 100%,
                                 50% 75%,
                                 0% 75%);
              clip-path: polygon(0% 0%,
                                 100% 0%,
                                 100% 75%,
                                 75% 75%,
                                 75% 100%,
                                 50% 75%,
                                 0% 75%);
    }
    .om{
      /* top: 100%;
      left: 50%; */
      transform: translateX(-50%);
      border-top-width: 0;
      border-bottom-color: rgba(0, 0, 0, 0.8);


      position: relative;
      /* height: 60px;
      width: 250px; */
      background: #f2395a;
      color: white;
      text-align: center;
      /* line-height: 60px;
      font-size: 18px; */
      margin: 0 auto;
    }
/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */


/* =========================
   SLIDING PANELS
   ========================= */
   .final__slide{
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
  .final__slide img{
    display: inline-block;
    vertical-align: middle;
  }
  .final__slide-content{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    width:100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding:20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: rgba(225, 35, 69, 0.9);
    color: #ffffff;
    transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
  }
  .final__slide:hover .final__slide-content{
    transform:translate(0, 0);
    -webkit-transform:translate(0, 0);
  }
  
  /*Directions*/
  .slide-top .final__slide-content{
    transform:translate(0, -100%);
    -webkit-transform:translate(0, -100%);
  }
  .slide-left .final__slide-content{
    transform:translate(-100%, 0);
    -webkit-transform:translate(-100%, 0);
  }
  .slide-right .final__slide-content{
    transform:translate(100%, 0);
    -webkit-transform:translate(100%, 0);
  }
  .slide-bottom .final__slide-content{
    transform:translate(0, 100%);
    -webkit-transform:translate(0, 100%);
  }
  
/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */

   .final__triangle {
    position: relative;
    height: 320px;
    -webkit-clip-path: polygon(50% 0%, 20% 100%, 80% 100%);
            clip-path: polygon(50% 0%, 20% 100%, 80% 100%);
  }
  
  .final__triangle div {
    position: absolute;
    top: 0;
    line-height: 100px;
    font-size: 0.8em;
    text-align: center;
    background: red;
    width: 100%;
    height: 100px;
    margin: 0 auto;
    transition: all ease 0.6s;
    color: red;
  }
  
  .final__triangle .top {
    z-index: 1;
  }
  
    .final__triangle:hover div {
      color: white;
    }
  
  .final__triangle:hover .mid {
    top: 110px;
    background: green;
  }
  
  .final__triangle:hover .bottom {
    top: 220px;
    background: blue;
  }
  
/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */


/* =========================
   CSS ACCORDION
   ========================= */
   .final__accordion {
    position: relative;
    display: block;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
  }

  .final__accordion .accordion__item {
    display: block;
  }

  .final__accordion .accordion__content {
    display: block;
    padding: 0;
    overflow: hidden;
    height: 0;
    transition: height 0.5s ease, padding 0.3s linear;
  }

  .final__accordion .accordion__content p {
    padding: 20px;
    margin:0;
  }

  .final__accordion .accordion__content:target {
    height: 150px;
  }

  .final__accordion .accordion__trigger {
    text-decoration: none;
  }

  .final__accordion .accordion__title {
    position: relative;
    display: block;
    margin: 0;
    padding: 10px;
    font-size: 1em;
    background-color: #00B3B4;
    color: #FFFFFF;
    border: 1px solid #379AA4;
    box-shadow: 0 1px 0 rgba(255, 255, 255, .5) inset, 0 -1px 0 rgba(255, 255, 255, .1) inset, 0 4px 0 #338A94, 0 4px 2px rgba(0, 0, 0, .5);
    cursor: pointer;
  }

  .final__accordion .accordion__title:hover {
    background-color: #0bbfc0;
  }

  .final__accordion .accordion__title:after {
    position: absolute;
    top: calc(50% - 5px);
    right: 20px;
    width: 7px;
    height: 7px;
    border-left: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    transform: rotate(-45deg);
    content: "";
  }

/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */


/* =========================
   3D LAYER EFFECT
   ========================= */

   .final__layers {
    position: relative;
    min-height: 638px;
    max-width: 360px;
  }
  
  .final__layers img {
    position: absolute;
    max-width: 100%;
    height: 100%;
    transition: all ease 1600ms;
    transform-style: preserve-3d;
  }
  
  .final__layers:hover img {
    /*
    The "perspective" CSS function (or property) enables 3D space and produces the illustion of "distance" between the Z plane and the user.
  
    Smaller value = exaggerated effect
    Larger value = subtle effect
    */
    transform: perspective(1200px) rotateX(50deg) rotateY(20deg) rotateZ(-25deg) translateX(35px) translateY(0px) translateZ(180px);
  }
  
  .final__layers img.mid {
    width: 320px;
    left: 20px;
  }
  
  .final__layers img.top {
    width: 300px;
    left: 30px;
    top: -5px;
  }
  
  .final__layers img.tippy {
    width: 280px;
    top: -150px;
    left: 40px;
  }
  
  .final__layers:hover img.mid {
    transform: perspective(1200px) rotateX(50deg) rotateY(20deg) rotateZ(-25deg) translateX(35px) translateY(0px) translateZ(240px);
  }
  
  .final__layers:hover img.top {
    transform: perspective(1200px) rotateX(50deg) rotateY(20deg) rotateZ(-25deg) translateX(35px) translateY(0px) translateZ(280px);
  }
  
  .final__layers:hover img.tippy {
    transform: perspective(1200px) rotateX(60deg) rotateY(20deg) rotateZ(-25deg) translateX(-20px) translateY(20px) translateZ(260px);
  }
  
.sepias {
    -webkit-filter: sepia(1);
            filter: sepia(1);
  }
  .sepias:hover {
    -webkit-filter: sepia(0);
            filter: sepia(0);
  }
/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */


/* =========================
   STICKY SIDEBAR
   ========================= */

   .sticky-sidebar-lesson {
    width: 80%;
    margin: 0 auto;
  }
  
  .sticky-sidebar-lesson::after {
    display: table;
    content: "";
    clear: both;
   
  }
  
  .sticky-sidebar-lesson .sandbox {
    width: 60%;
    margin: 0 0 40px;
    float: left;
  
  }
  
  .sticky-sidebar {
    background: #e7eef1;
    padding: 40px 40px 60px;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: 0 12px 0 -6px #fa90a3;
  
    position: fixed;
    right: 5%;
    width: 36%;
    float: right;
    border-radius: 200px;
  }
  
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

body{
  background: #24C6DC; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #24C6DC , #514A9D); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}




input[type="email"] {
  width: 480px;
  margin-top: 20px;
  padding: 10px;
  font-size: 30px;
  border: none;
  border-bottom: solid 2px #c9c9c9;
  transition: border 0.3s;
}

input[type="password"] {
  width: 480px;
  margin-top: 20px;
  padding: 10px;
  font-size: 30px;

  border: none;
  border-bottom: solid 2px #c9c9c9;
  transition: border 0.3s;
}
input[type="email"]:focus,
input[type="password"].focus {
  border-bottom: solid 2px #969696;
}

button {
  font-size: 30px;
  background-color: #00B16A;
  color: #fff;
  padding: 10px 30px;
  margin-top: 20px;
  margin-right: 10px;

}

p{
  color: orange;
  text-decoration: underline;
}

.hide{
  display: none;
}

.google{
  font-size: 30px;
  background-color: #dd4b39;
  color: #fff;
  padding: 10px 30px;
  margin-top: 20px;
  margin-right: 10px;
}

.card {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 6px;
  border: 6px solid #ff2058;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff2058 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ingredient-form {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;
}

.form-control label,
.form-control input {
  display: block;
  width: 100%;
}

.form-control input {
  font: inherit;
  padding: 0.1rem 0.25rem;
  border: none;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1rem;
}

.form-control input:focus {
  outline: none;
  border-bottom-color: #ff2058;
}

.ingredient-form__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
button{
  padding: 15px 25px ;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  justify-content: center;

}
.button:hover {background-color: #3e8e41}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
.ingredient-list {
  width: 30rem;
  max-width: 80%;
  margin: auto;
}

.ingredient-list h2 {
  border-bottom: 3px solid #ccc;
  padding-bottom: 1rem;
}

.ingredient-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ingredient-list li {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: space-between;
}

.error-modal {
  position: fixed;
  top: 30vh;
  left: calc(50% - 15rem);
  width: 30rem;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 100;
  border-radius: 7px;
}

.error-modal h2 {
  margin: 0;
  padding: 1rem;
  background: #ff2058;
  color: white;
  border-radius: 7px 7px 0 0;
}

.error-modal p {
    padding: 1rem;
}

.error-modal__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 50;
}

.search {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;
}

.search-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.search-input input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.15rem 0.25rem;
}

.search-input input:focus {
  outline: none;
  border-color: #ff2058;
}

@media (min-width: 768px) {
  .search-input {
    flex-direction: row;
  }
}

/* ==========================================
   FINAL.CSS
   These are the final styles for the project.

   Course:  CSS3 in 30 Days
   Author:  Brad Hussey
   Website: codecollege.ca
   ========================================== */


/* =========================
   PROGRESS BARS #1
   ========================= */
   .final__animation-bar-1 {
    position: relative;
    display: block;
    padding: 5px;
    font-size: 16px;
    line-height: 16px;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  }
  .final__animation-bar-1 span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    background-color: #f56982;
    background-size: 100%;
    background-image: linear-gradient(to bottom, #f2395a, #b90c2b);
    -webkit-animation: progress-anim-1 10s infinite linear;
            animation: progress-anim-1 10s infinite linear;
  }
  .final__animation-bar-1 span:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-size: 100%;
    background-image: linear-gradient(45deg, #ffffff 25%,
                                     rgba(0, 0, 0, 0) 25%,
                                     rgba(0, 0, 0, 0) 50%,
                                     #ffffff 50%,
                                     #ffffff 75%,
                                     rgba(0, 0, 0, 0) 75%,
                                     rgba(0, 0, 0, 0));
    background-size: 30px 30px;
    opacity: 0.3;
    -webkit-animation: progress-anim-1-after 0.5s infinite linear;
            animation: progress-anim-1-after 0.5s infinite linear;
  }


  @-webkit-keyframes progress-anim-1 {
    0% {
      width: 20%;
    }
    50% {
      width: 80%;
    }
    100% {
      width: 20%;
    }
  }
  @keyframes progress-anim-1 {
    0% {
      width: 20%;
    }
    50% {
      width: 80%;
    }
    100% {
      width: 20%;
    }
  }
  @-webkit-keyframes progress-anim-1-after {
    0% {
      background-position: 0 100%;
    }
    100% {
      background-position: 30px 100%;
    }
  }
  @keyframes progress-anim-1-after {
    0% {
      background-position: 0 100%;
    }
    100% {
      background-position: 30px 100%;
    }
  }

  /* =========================
     PROGRESS BARS #2
     ========================= */
  .final__animation-bar-2 {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  }
  .final__animation-bar-2 span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    background-color: #00e6e7;
    background-size: 100%;
    background-image: linear-gradient(to bottom, #00b3b4, #008081);
    -webkit-animation: progress-anim-2 10s infinite ease;
            animation: progress-anim-2 10s infinite ease;
  }
  .final__animation-bar-2 span:before {
    position: absolute;
    right: 0;
    bottom: 100%;
    display: inline-block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-right-width: 0;
    border-bottom-width: 0;
    border-top-color: rgba(0, 0, 0, 1);
    content: "";
  }
  .final__animation-bar-2 span:after {
    position: absolute;
    right: 0;
    bottom: calc(100% + 5px);
    z-index: 1;
    display: inline-block;
    content: attr(data-label);
    padding: 5px;
    border-radius: 3px;
    font-size: 0.8em;
    background-color: rgba(0, 0, 0, 1);
    color: #FFFFFF;
  }


  @-webkit-keyframes progress-anim-2 {
    0% {
      width: 20%;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 20%;
    }
  }
  @keyframes progress-anim-2 {
    0% {
      width: 20%;
    }
    50% {
      width: 100%;
    }
    100% {
      width: 20%;
    }
  }

.card {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 6px;
  border: 6px solid #ff2058;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff2058 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ingredient-form {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;
  background-image: linear-gradient(135deg, #0eeff0 30%, #088f90 90%);
  position: relative;
  z-index: 3;
  padding: 100px;
  font-size:16px;
  color: #999999;
}

.form-control label,
.form-control input {
  display: block;
  width: 100%;






  display: inline-block;
  width: 100%;
  padding: 0 20px;
  line-height: 50px;
  height: 50px;
  background-color: white;
  border: 1px solid #e6e6e6;
  border-bottom-width: 3px;
  border-radius: 5px;
  outline: 0;
  transition: all 0.3s ease;
}

.form-control input {
  font: inherit;
  padding: 0.1rem 0.25rem;
  border: none;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1rem;
}

.form-control input:focus {
  outline: none;
  border-bottom-color: #ff2058;
}

.ingredient-form__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  display: block;
  width: 100%;
  max-width: 300px;
  margin: auto;
  padding: 30px;
  background-color: white;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
button{
  padding: 15px 25px ;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
  justify-content: center;




  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0 20px;
  font-size: 1rem;
  background-color: #f2395a;
  background-size: 100%;
  background-image:          linear-gradient(45deg,
                                             rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%,
                                             rgba(0, 0, 0, 0) 50%,
                                             rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%,
                                             rgba(0, 0, 0, 0));
  background-size: 20px 20px, 100% 100%;
  border: 1px solid #f02146;
  border-bottom-width: 3px;
  color: white;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;

}
.button:hover {background-color: #3e8e41



}

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);




  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  transform: scale(0.9) translate(0, 0);
  transition: all 0.3s ease;
}
.ingredient-list {
  width: 30rem;
  max-width: 80%;
  margin: auto;
}

.ingredient-list h2 {
  border-bottom: 3px solid #ccc;
  padding-bottom: 1rem;
}

.ingredient-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ingredient-list li {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: space-between;
}

.error-modal {
  position: fixed;
  top: 30vh;
  left: calc(50% - 15rem);
  width: 30rem;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 100;
  border-radius: 7px;
}

.error-modal h2 {
  margin: 0;
  padding: 1rem;
  background: #ff2058;
  color: white;
  border-radius: 7px 7px 0 0;
}

.error-modal p {
    padding: 1rem;
}

.error-modal__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 50;
}

.search {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;
}

.search-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.search-input input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.15rem 0.25rem;
}

.search-input input:focus {
  outline: none;
  border-color: #ff2058;
}

@media (min-width: 768px) {
  .search-input {
    flex-direction: row;
  }
}

.main-footer {
  color: white;
  background-color: #dc3545;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
 
}
h1{
  color:chartreuse;
  
    
  

}
li{
  font-family: 'Pacifico', cursive;
 
}

