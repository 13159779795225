.main-footer {
  color: white;
  background-color: #dc3545;
  padding-top: 3em;
  position: relative;
  bottom: 0;
  width: 100%;
 
}
h1{
  color:chartreuse;
  
    
  

}
li{
  font-family: 'Pacifico', cursive;
 
}
